<script>
import KalioAlert from './kalio-alert.vue';

export default {
  name: 'KalioFlashAlert',
  components: { KalioAlert },
  props: {
    flash: { type: Array, required: true },
  },
  computed: {
    status() {
      return this.flash[0];
    },
    message() {
      return this.flash[1];
    },
    title() {
      return typeof this.message === 'string' ? this.message : Object.values(this.message)[0];
    },
    description() {
      return typeof this.message === 'string' ? null : Object.values(this.message)[1];
    },
  },
};
</script>

<template>
  <kalio-alert
    :status="status"
    :title="title"
    :description="description"
  />
</template>
