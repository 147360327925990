import { ref, computed, readonly } from 'vue';
import { defineStore } from 'pinia';
import { useVirtualRoundStore } from '@/demo_day_pack/stores';
import useBatchStore from './batch.js';

const useRolesStore = defineStore('roles', () => {
  const batchStore = useBatchStore();
  const virtualRoundStore = useVirtualRoundStore();

  const roles = ref([]);

  function setCurrentUserRoles(_roles) {
    roles.value = _roles;
  }

  const isApplicationReviewer = computed(() => !!roles.value.find(role => role.name === 'application_reviewer'));
  const isPartner = computed(() => !!roles.value.find((role) => role.name === 'partner'));
  const isMentor = computed(() => !!roles.value.find((role) => role.name === 'batch_group_mentor'));
  const isFounder = computed(() => !!roles.value.find((role) => role.name === 'founder'));
  const isBatchFounder = computed(
    () => !!roles.value.find((role) => role.name === 'founder' && role.resourceType === 'Batch'),
  );
  const isPortfolioFounder = computed(() => !!roles.value.find((role) => role.name === 'portfolio_founder'));
  const isCurrentBatchFounder = computed(
    () => !!roles.value.find((role) => role.name === 'founder' &&
      role.resourceType === 'Batch' &&
      role.resourceId === batchStore.currentBatch?.id),
  );
  const isPlatanusTeamMember = computed(() => !!roles.value.find(role => role.name === 'platanus_team_member'));
  const isCommunityMember = computed(
    () => !!roles.value.find((role) =>
      ['founder', 'platanus_team_member', 'partner', 'fund_lp'].includes(role.name)),
  );
  const isBuildSprintReviewer = computed(() => !!roles.value.find(role => role.name === 'build_sprint_reviewer'));

  // @TODO this should not use the demo day pack store, instead the backend
  // should pass the current demo day as a global variable in window,
  // or maybe the roles should come pre processed from the backend.
  const isCurrentDemoDayInvestor = computed(() => !!roles.value.find(
    role => role.name === 'demo_day_investor' &&
          role.resourceType === 'DemoDay' &&
          role.resourceId === virtualRoundStore.demoDay?.id,
  ));
  const isOfficeHourProvider = computed(() => !!roles.value.find(
    role => role.name === 'office_hour_provider',
  ));

  function isCompanyFounder(company) {
    return !!roles.value.find(role => role.resourceType === 'Company' && role.resourceId === company.id);
  }

  return {
    roles: readonly(roles),
    isApplicationReviewer,
    isPartner,
    isMentor,
    isFounder,
    isBatchFounder,
    isBuildSprintReviewer,
    isPortfolioFounder,
    isCurrentBatchFounder,
    isPlatanusTeamMember,
    isCommunityMember,
    isCurrentDemoDayInvestor,
    isOfficeHourProvider,
    setCurrentUserRoles,
    isCompanyFounder,
  };
});

export { useRolesStore as default };
