import { computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import useReactiveStorage from '@/hooks/useReactiveStorage';
import useStartupApplicationStore from './startup_application';

function defaultValueFn() {
  return {
    discardReason: null,
    discardReasonKeywords: null,
    discardFeedback: null,
  };
}

const useStartupApplicationDiscardStore = defineStore('startupApplicationDiscard', () => {
  const {
    currentStartupApplicationId: startupApplicationId,
  } = storeToRefs(useStartupApplicationStore());

  const { value: discardData, set: setDiscardData } = useReactiveStorage(
    startupApplicationId,
    'discard-data',
    defaultValueFn,
  );

  const discardReason = computed({
    get: () => discardData.value.discardReason,
    set: (newValue) => {
      const updatedData = { ...discardData.value, discardReason: newValue };
      setDiscardData(updatedData);
    },
  });

  const discardReasonKeywords = computed({
    get: () => discardData.value.discardReasonKeywords,
    set: (newValue) => {
      const updatedData = { ...discardData.value, discardReasonKeywords: newValue };
      setDiscardData(updatedData);
    },
  });

  const discardFeedback = computed({
    get: () => discardData.value.discardFeedback,
    set: (newValue) => {
      const updatedData = { ...discardData.value, discardFeedback: newValue };
      setDiscardData(updatedData);
    },
  });

  return {
    discardReason,
    discardReasonKeywords,
    discardFeedback,
  };
});

export { useStartupApplicationDiscardStore as default };

