import { computed, watch } from 'vue';
import useLocalStorage from './useLocalStorage';

export default function useReactiveStorage(refObj, key, defaultValueFn = () => ({})) {
  const storedValue = useLocalStorage(key, {});

  watch(refObj, () => {
    if (!storedValue.value[refObj.value]) {
      storedValue.value[refObj.value] = defaultValueFn();
    }
  }, { immediate: true });

  function set(value) {
    storedValue.value[refObj.value] = value;
  }

  const value = computed(() => storedValue.value[refObj.value]);

  return { set, value };
}

