import api from '@/api/api';

export default {
  get(id) {
    const path = `/api/v1/demo_day/demo_days/${id}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  update(id, demoDay) {
    const path = `/api/v1/demo_day/demo_days/${id}`;

    return api({
      method: 'put',
      url: path,
      data: { demoDay },
    });
  },
};
