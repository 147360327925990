const DATA_AVAILABLE_INTERVAL_FIRE_MS = 1000;

// eslint-disable-next-line import/prefer-default-export
export class DailyMediaRecorder {
  constructor(participants = []) {
    this.participants = participants;
    this.recorders = [];
    this.audioChunks = [];
  }

  addParticipant(participant) {
    const mediaRecorder = new MediaRecorder(
      participant.mediaSources.audio,
      { mimeType: 'audio/webm;codecs=opus' },
    );

    mediaRecorder.ondataavailable = e => {
      this.audioChunks.push(e.data);
    };

    this.recorders.push(mediaRecorder);
  }

  removeParticipant(participant) {
    // @TODO how to find the recorder for a participant?
    // const recorder = this.recorders.find(r => r.participant === participant);
    // recorder.stop();
    //
    // this.recorders = this.recorders.filter(r => r.participant !== participant);
  }

  start(datavailableInterval = DATA_AVAILABLE_INTERVAL_FIRE_MS) {
    this.recorders.forEach(recorder => recorder.start(datavailableInterval));
  }

  stop() {
    this.recorders.forEach(recorder => {
      if (recorder.state !== 'inactive') {
        recorder.stop();
      }
    });
  }

  restart() {
    this.stop();
    this.start();
    this.audioChunks = [];
  }

  getAudioFile() {
    const blob = new Blob(this.audioChunks, { type: 'audio/webm; codecs=opus' });

    return new File([blob], 'audio.webm');
  }
}

