<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  status: {
    type: String,
    default: 'success',
    validator: value => ['success', 'alert', 'notice'].includes(value),
  },
  description: { type: String, default: null },
  title: { type: String, default: null },
  deletable: { type: Boolean, default: true },
  customIcon: { type: String, default: null },
  size: {
    type: String,
    default: 'big',
    validator: value => ['small', 'medium', 'big'].includes(value),
  },
});

const emit = defineEmits(['close']);
const closed = ref(false);
const color = computed(() => {
  const classes = {
    success: 'emerald',
    alert: 'red',
    notice: 'amber',
  };

  return classes[props.status];
});

const icon = computed(() => {
  if (props.customIcon) return props.customIcon;

  const icons = {
    alert: 'close.svg',
    notice: 'notice.svg',
    success: 'check.svg',
  };

  return icons[props.status];
});

const titleClasses = computed(() => {
  const classes = {
    small: 'text-xs sm:text-xs font-light',
    medium: 'text-xs sm:text-sm',
    big: 'text-sm font-medium sm:text-base',
  };

  return classes[props.size];
});

function close() {
  closed.value = true;
  emit('close');
}
</script>

<template>
  <div
    class="relative flex w-full items-center gap-3 rounded-md p-3"
    :class="[`bg-${color}-50`, { hidden: closed }, { 'my-2': !closed }]"
  >
    <div v-if="status === 'notice'">
      <inline-svg
        :src="require(`assets/images/icons/${icon}`)"
        class="size-5 fill-current text-amber-400"
      />
    </div>
    <div
      v-else
      class="flex items-center justify-center rounded-full p-1"
      :class="`bg-${color}-400`"
    >
      <inline-svg
        :src="require(`assets/images/icons/${icon}`)"
        class="size-3 fill-current text-blue-gray-50"
      />
    </div>
    <div
      class="flex flex-col pr-8"
      :class="{ 'space-y-3': !!description }"
    >
      <span
        class="text-cool-gray-700"
        :class="titleClasses"
      >
        {{ title }}
      </span>
      <span
        v-if="description"
        class="text-xs text-cool-gray-500 sm:text-sm"
      >
        {{ description }}
      </span>
      <slot v-else />
    </div>
    <button
      v-if="deletable"
      class="absolute right-2 top-3 rounded-full focus:outline-none"
      :class="`hover:bg-${color}-100`"
      @click.prevent="close"
    >
      <inline-svg
        :src="require('assets/images/icons/close.svg')"
        class="size-5 fill-current"
        :class="`text-${color}-400`"
      />
    </button>
  </div>
</template>
