import { ref, watch } from 'vue';
export default function useLocalStorage(key, defaultValue, { expireInSeconds } = {}) { const retrieveValue = () => { const storedItem = localStorage.getItem(key); if (!storedItem)
    return defaultValue; const { value, updatedAt } = JSON.parse(storedItem); if (expireInSeconds === undefined)
    return value; const now = Date.now(); if (updatedAt + expireInSeconds * 1000 < now) {
    localStorage.removeItem(key);
    return defaultValue;
} return value; }; const value = ref(retrieveValue()); watch(value, newValue => { if (newValue === null) {
    localStorage.removeItem(key);
    return;
} const storedValue = { value: newValue, updatedAt: Date.now() }; localStorage.setItem(key, JSON.stringify(storedValue)); }, { deep: true }); return value; }
