import Vuex from 'vuex';
import form from './form';

const store = new Vuex.Store({
  modules: {
    form,
  },
});

export default store;
