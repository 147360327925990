import { defineStore } from 'pinia';
import useLocalStorage from '@/hooks/useLocalStorage';
// eslint-disable-next-line no-magic-numbers
const expirationTime = 60 * 60 * 24 * 30;
const useConnectionsStore = defineStore('connection', () => {
    const investorInfo = useLocalStorage('investorInfo', null, { expireInSeconds: expirationTime });
    const connections = useLocalStorage('connections', [], { expireInSeconds: expirationTime });
    function setConnections(value) {
        connections.value = value;
    }
    function setInvestorInfo(value) {
        investorInfo.value = value;
    }
    return {
        investorInfo,
        setInvestorInfo,
        connections,
        setConnections,
    };
});
export { useConnectionsStore as default };
