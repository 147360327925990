const initialState = {
  sections: [],
  currentSection: null,
};

const actions = {
  setSections({ commit }, sections) {
    commit('setSections', sections);
  },
  setCurrentSection({ commit }, section) {
    commit('setCurrentSection', section);
  },
  setSectionState({ commit }, { name, valid }) {
    commit('setSectionState', { name, valid });
  },
  setVisibleSection({ commit }, section) {
    commit('setVisibleSection', section);
  },
  addSection({ commit }, section) {
    commit('addSection', section);
  },
};

const mutations = {
  setSections(state, sections) {
    state.sections = sections;
  },
  setCurrentSection(state, section) {
    state.currentSection = section;
  },
  setSectionState(state, { name, valid }) {
    const index = state.sections.findIndex(section => section.name === name);
    if (index !== -1) {
      state.sections[index] = { ...state.sections[index], valid };
    }
  },
  setVisibleSection(state, name) {
    state.currentSection = state.sections.find(_section => _section.name === name);
  },
  addSection(state, name) {
    state.sections.push({ name });
  },
};

const getters = {
  validSection: (state) => (section) => state.sections.find(_section => _section.name === section.name)?.valid,
  currentSection: (state) => state.currentSection,
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
