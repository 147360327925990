import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

const SECONDS_UNTIL_SHOULD_AUTOMATICALLY_ADVANCE = 60;

export default defineStore('demoDayStreaming', () => {
  const liveDemoDayCompanyId = ref(null);
  const secondsSinceLastUserAction = ref(SECONDS_UNTIL_SHOULD_AUTOMATICALLY_ADVANCE);
  const canIncreaseSecondsSinceLastUserAction = ref(true);

  const canAutomaticallyChangeSelectedDemoDayCompany = computed(() =>
    secondsSinceLastUserAction.value >= SECONDS_UNTIL_SHOULD_AUTOMATICALLY_ADVANCE,
  );

  function increaseSecondsSinceLastUserAction() {
    if (canIncreaseSecondsSinceLastUserAction.value) {
      secondsSinceLastUserAction.value += 1;
    }
  }

  function resetTimeSinceLastUserAction() {
    secondsSinceLastUserAction.value = 0;
  }

  function setLiveDemoDayCompanyId(value) {
    liveDemoDayCompanyId.value = value;
  }

  function lockUserAction() {
    canIncreaseSecondsSinceLastUserAction.value = false;
  }

  function unlockUserAction() {
    canIncreaseSecondsSinceLastUserAction.value = true;
  }

  return {
    liveDemoDayCompanyId,
    resetTimeSinceLastUserAction,
    increaseSecondsSinceLastUserAction,
    setLiveDemoDayCompanyId,
    canAutomaticallyChangeSelectedDemoDayCompany,
    lockUserAction,
    unlockUserAction,
  };
});
