import { ref } from 'vue';
import { defineStore } from 'pinia';
import demoDaysApi from '../api/demo_days.js';

export default defineStore('demoDayVirtualRound', () => {
  const demoDay = ref(null);
  function setDemoDay(value) {
    demoDay.value = value;
  }

  const auctions = ref(null);
  function setAuctions(value) {
    auctions.value = value;
  }

  const investorAttendance = ref(null);
  function setInvestorAttendance(value) {
    investorAttendance.value = value;
  }

  function getAuction(company) {
    return auctions.value?.find(auction => auction.investableId === company.id);
  }

  const state = {
    demoDay,
    auctions,
    investorAttendance,
  };

  const actions = {
    setDemoDay,
    setAuctions,
    setInvestorAttendance,
    getAuction,
    refetchDemoDay: () => demoDaysApi.get(demoDay.value.id)
      .then((response) => setDemoDay(response.demoDay || response)),
  };

  return {
    ...state,
    ...actions,
  };
});
