import { ref } from 'vue';
import { defineStore } from 'pinia';

export default defineStore('batch', () => {
  const currentBatch = ref(null);
  const batchGroups = ref([]);

  function setCurrentBatch(batch) {
    currentBatch.value = batch;
  }

  function setBatchGroups(groups) {
    batchGroups.value = groups;
  }

  function addBatchGroup(group) {
    batchGroups.value = [...batchGroups.value.filter((g) => g.id !== group.id), group];
  }

  function setBatchGroup(group) {
    batchGroups.value = batchGroups.value.map((g) => {
      if (g.id === group.id) {
        return group;
      }

      return g;
    });
  }

  return {
    currentBatch,
    setCurrentBatch,
    batchGroups,
    setBatchGroups,
    addBatchGroup,
    setBatchGroup,
  };
});
