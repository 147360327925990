import { ref, readonly } from 'vue';
import { defineStore } from 'pinia';
import { setLocale } from '@vee-validate/i18n';
import i18n from '../plugins/i18n';

const useUserStore = defineStore('user', () => {
  const currentUser = ref(null);

  function setCurrentUser(user) {
    currentUser.value = user;

    if (user.locale) {
      i18n.global.locale = user.locale;
      setLocale(user.locale);
    }
  }

  return {
    currentUser: readonly(currentUser),
    setCurrentUser,
  };
});

export { useUserStore as default };
