const INITIAL_FILTERS = {
  search: '',
  applicationProcessId: 'Todos',
  hasFemaleFounder: null,
  hasTechnicalFounder: null,
  aasmState: 'Todos',
  country: 'Todos',
  qualification: 'Todos',
  reviewerId: 'Todos',
  discardEmailState: 'Todos',
  referred: null,
  appliedMoreThanOnce: null,
  reachedInterview: null,
  tags: [],
  industries: [],
  applicationPeriods: [],
  educationInstitution: '',
  previousCompany: '',
  revenueToFullTimeMonths: '',
  revenueToWorkingInIdeaMonths: '',
};

function getFiltersFromQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const filters = {};

  Object.keys(INITIAL_FILTERS).forEach((key) => {
    const value = searchParams.get(key);
    filters[key] = value || INITIAL_FILTERS[key];

    if (['tags', 'industries', 'applicationPeriods'].includes(key)) {
      filters[key] = value ? value.split(',') : [];
    } else if (value === 'true') {
      filters[key] = true;
    } else if (value === 'false') {
      filters[key] = false;
    } else if (key === 'qualification' && value === 'no_qualification') {
      filters[key] = 'Sin calificación';
    }
  });

  return filters;
}

function getVideoId(videoUrl) {
  if (!videoUrl) return null;

  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const match = videoUrl.match(regExp);
  const videoId = (match && match[1]) ? match[1] : null; // eslint-disable-line no-magic-numbers

  return videoId;
}

function getVideoEmbedUrl(videoUrl) {
  const videoId = getVideoId(videoUrl);
  if (!videoId) return undefined;

  return `https://www.youtube.com/embed/${videoId}`;
}

export {
  INITIAL_FILTERS,
  getFiltersFromQueryParams,
  getVideoEmbedUrl,
  getVideoId,
};
