function activateTargetBlankLinks(className) {
  if (document.getElementsByClassName(className).length > 0) {
    const content = document.getElementsByClassName(className)[0];

    const links = content.getElementsByTagName('a');
    for (const link of links) {
      link.setAttribute('target', '_blank');
    }
  }
}

export default activateTargetBlankLinks;
