/* eslint-disable complexity */
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { getFiltersFromQueryParams, INITIAL_FILTERS } from '../utils/review.js';

const useReviewFiltersStore = defineStore('reviewFilters', () => {
  const filters = ref(getFiltersFromQueryParams());

  const appliedFilters = computed(() => {
    const _filters = {};
    const values = filters.value;

    if (values.search) {
      _filters.search = values.search;
    }

    if (values.applicationProcessId !== 'Todos') {
      _filters.applicationProcessId = values.applicationProcessId;
    }

    if (values.hasFemaleFounder !== null) {
      _filters.hasFemaleFounder = values.hasFemaleFounder;
    }

    if (values.hasTechnicalFounder !== null) {
      _filters.hasTechnicalFounder = values.hasTechnicalFounder;
    }

    if (values.aasmState !== 'Todos') {
      _filters.aasmState = values.aasmState;
    }

    if (values.country !== 'Todos') {
      _filters.country = values.country;
    }

    if (values.qualification !== 'Todos') {
      if (values.qualification === 'Sin calificación') {
        _filters.qualification = 'no_qualification';
      } else {
        _filters.qualification = values.qualification;
      }
    }

    if (values.reviewerId !== 'Todos') {
      _filters.reviewerId = values.reviewerId;
    }

    if (values.discardEmailState !== 'Todos') {
      _filters.discardEmailState = values.discardEmailState;
    }

    if (values.tags.length > 0) {
      _filters.tags = values.tags;
    }

    if (values.industries.length > 0) {
      _filters.industries = values.industries;
    }

    if (values.applicationPeriods.length > 0) {
      _filters.applicationPeriods = values.applicationPeriods;
    }

    if (values.educationInstitution) {
      _filters.educationInstitution = values.educationInstitution;
    }

    if (values.previousCompany) {
      _filters.previousCompany = values.previousCompany;
    }

    if (values.revenueToFullTimeMonths) {
      _filters.revenueToFullTimeMonths = values.revenueToFullTimeMonths;
    }

    if (values.revenueToWorkingInIdeaMonths) {
      _filters.revenueToWorkingInIdeaMonths = values.revenueToWorkingInIdeaMonths;
    }

    if (values.referred !== null) {
      _filters.referred = values.referred;
    }

    if (values.appliedMoreThanOnce !== null) {
      _filters.appliedMoreThanOnce = values.appliedMoreThanOnce;
    }

    if (values.reachedInterview !== null) {
      _filters.reachedInterview = values.reachedInterview;
    }

    return _filters;
  });

  function addFilters(values) {
    filters.value = { ...filters.value, ...values };
  }

  function resetFilters(keys) {
    const _filters = { ...filters.value };

    keys.forEach((key) => {
      _filters[key] = INITIAL_FILTERS[key];
    });

    filters.value = _filters;
  }

  return {
    filters,
    appliedFilters,
    addFilters,
    resetFilters,
  };
});

export { useReviewFiltersStore as default };
