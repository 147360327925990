import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { DailyMediaRecorder } from '@/utils/daily.js';

function handleVideo(participant) {
  // early return if participant has not given video permission
  if (!participant?.video) return null;

  const track = participant.tracks.video.persistentTrack;
  const newStream = new MediaStream([track]);

  return newStream;
}

function handleAudio(participant) {
  // If the participant is local or has their audio off, early out.
  if (!participant?.local || !participant?.audio) return null;

  const track = participant.tracks.audio.persistentTrack;
  const newStream = new MediaStream([track]);

  return newStream;
}

const useDailyStore = defineStore('daily', () => {
  const callObject = ref(null);
  const participants = ref([]);
  const dailyMediaRecorder = new DailyMediaRecorder();
  const localParticipant = computed(() => participants.value?.find((p) => p.local));

  function setCallObject(co) {
    callObject.value = co;
  }

  function addParticipant(participant) {
    participants.value = [
      participants.value.filter(p => p.id !== participant.id),
      participant,
    ];
  }

  const _participantsKey = ref(1);
  function updateParticipants() {
    if (!callObject.value) return;

    participants.value = Object.entries(callObject.value.participants()).map(
      ([key, participant]) => ({
        ...participant,
        mediaSources: {
          audio: handleAudio(participant),
          video: handleVideo(participant),
        },
        id: key,
        key: `${key}-${_participantsKey.value++}`,
      }),
    );
  }

  return {
    callObject,
    participants,
    localParticipant,
    dailyMediaRecorder,
    setCallObject,
    addParticipant,
    updateParticipants,
  };
});

export { useDailyStore as default };
