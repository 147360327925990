import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

const useStartupApplicationStore = defineStore('startupApplication', () => {
  const startupApplications = ref([]);
  const currentStartupApplicationIndex = ref(0);
  const currentStartupApplication = computed(
    () => startupApplications.value[currentStartupApplicationIndex.value],
  );
  const currentStartupApplicationId = computed(() => currentStartupApplication.value?.id);

  function setStartupApplications(value) {
    const previousApplication = currentStartupApplication.value;
    startupApplications.value = value;

    if (previousApplication) {
      const newcurrentStartupApplicationIndex = startupApplications.value.findIndex(
        (application) => application.id === previousApplication.id,
      );

      if (newcurrentStartupApplicationIndex >= 0) {
        currentStartupApplicationIndex.value = newcurrentStartupApplicationIndex;
        startupApplications.value[newcurrentStartupApplicationIndex] = previousApplication;
      } else {
        currentStartupApplicationIndex.value = 0;
      }
    }
  }

  function nextStartupApplication() {
    const nextApplication = startupApplications.value[currentStartupApplicationIndex.value + 1];
    if (nextApplication) {
      currentStartupApplicationIndex.value += 1;
    }
  }
  function previousStartupApplication() {
    const previousApplication = startupApplications.value[currentStartupApplicationIndex.value - 1];
    if (previousApplication) {
      currentStartupApplicationIndex.value -= 1;
    }
  }
  function goToStartupApplication(id) {
    const index = startupApplications.value.findIndex((application) => application.id === id);
    if (index >= 0) {
      currentStartupApplicationIndex.value = index;
      startupApplications.value[index] = { ...startupApplications.value[index], full: false };
    }
  }
  function setFullStartupApplication(application) {
    const index = startupApplications.value.findIndex((app) => app.id === application.id);
    if (index >= 0) {
      startupApplications.value[index] = { ...application, full: true };
    }
  }

  return {
    startupApplications,
    setStartupApplications,
    previousStartupApplication,
    nextStartupApplication,
    goToStartupApplication,
    setFullStartupApplication,
    currentStartupApplicationId,
    currentStartupApplication,
    currentStartupApplicationIndex,
  };
});

export { useStartupApplicationStore as default };
